import { Link, navigate } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import * as styles from './styles.module.scss'



const ThirdContainerSidebar = ({ sideBarContent, children, fullwidth, style, alignBottom, sidebarStyle, contentStyle, sameHeight, mobileReverse }) => {

    const sidebarRef = useRef();
    const contentRef = useRef();

    const [widthSidebarBackground, setWidthSidebarBackground] = useState(0);
    const [widthContentBackground, setWidthContentBackground] = useState(0);
    const [heightContent, setHeightContent] = useState('auto');


    const calcWidths = () => {

        const windowWidth = document.documentElement.clientWidth;
        let sidebarWidth = '100%';
        let contentWidth = '100%';

        if (windowWidth > 992) {

            sidebarWidth = '40%';
            contentWidth = '60%';

            if (windowWidth > 1320) {
                sidebarWidth = (1320 / 3) + ((windowWidth - 1320) / 2);
                contentWidth = windowWidth - sidebarWidth
            }


        }

        setWidthSidebarBackground(sidebarWidth)
        setWidthContentBackground(contentWidth)

    }

    const calcHeight = () => {
        const windowWidth = document.documentElement.clientWidth;
        if (sidebarRef.current !== null && windowWidth > 992) {
            setHeightContent(sidebarRef.current.clientHeight);
        } else if (sidebarRef.current !== null && windowWidth <= 992) {
            setHeightContent('auto');
        }
    }

    useEffect(() => {

        if (sameHeight === true) {
            calcHeight();
            window.addEventListener('resize', calcHeight);
        }

        calcWidths();
        window.addEventListener('resize', calcWidths);

        return () => {
            window.removeEventListener("resize", calcWidths);
        }

    }, [])

    return <div className={`${styles.bgContainer} ${alignBottom ? styles.alignBottom : undefined} ${mobileReverse ? styles.mobileReverse : undefined}`}
        style={style}>
        <div
            className={styles.sidebar}
            style={{ ...sidebarStyle, width: widthSidebarBackground }}
            ref={sidebarRef}
        >
            <div className={`${styles.sidebarInner}`}>
                <div>
                    {sideBarContent}
                </div>
            </div>
        </div>
        <div
            className={styles.content}
            style={{ ...contentStyle, width: widthContentBackground, height: heightContent }}
        >
            <div className={`${styles.contentInner} ${fullwidth ? styles.fullwidth : undefined}`}>
                {children}
            </div>
        </div>
    </div>

}

export default ThirdContainerSidebar;