import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form";
import Button from "../components/elements/Button"
import TextInput from "../components/elements/Inputs/TextInput";
import Checkbox from "../components/elements/Inputs/Checkbox";
import Layout from "../components/layout"
import axios from "axios";
import { API_BASE } from "../spectory-config";
import LoadingSpinner from "../components/elements/LoadingSpinner";
import ThirdContainerSidebar from "../components/elements/ThirdContainerSidebar";
import PageInfo from "../components/elements/PageInfo";
import { navigate, withPrefix } from "gatsby";
import { useDispatch } from "react-redux";
import { setNotification } from "../store/actions";


import KundenkartenImage from '../images/Kundenkarten.jpg';

const ActivateWithCard = (props) => {
    const { register, handleSubmit } = useForm();


    const [loading, setLoading] = useState(false);
    const [loginError, setLoginError] = useState('');

    const dispatch = useDispatch();

    console.log("PROPS", props)

    const submitResetPassword = async (data) => {

        setLoading(true);

        data.key = props.resetkey;
        data.email = props.email;

        var config = {
            method: 'post',
            url: `${API_BASE}/auth/restorepassword`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        console.log("TO SERVER", config)

        return axios(config)
            .then(function (response) {
                console.log("response", response)
                if (response.data.c === 200) {
                    dispatch(setNotification({
                        title: 'Ihr Passwort wurde zurückgesetzt. Sie können sich nun wieder anmelden.',
                        type: "success",
                        show: true
                    }));
                } else {
                    dispatch(setNotification({
                        title: 'Etwas ist schief gelaufen, bitte versuchen Sie es erneut.',
                        type: "error",
                        show: true
                    }));
                }

                setLoading(false);
            })
            .catch(function (error) {
                console.log("error", error)
                setLoading(false);
                dispatch(setNotification({
                    title: 'Etwas ist schief gelaufen, bitte versuchen Sie es erneut.',
                    type: "error",
                    show: true
                }));
            });
    }


    const renderResetPassword = <>
        <h2>Online-Konto aktivieren</h2>
        <br />
        <br />
        <p>
            Aktivieren Sie jetzt Ihr Online-Konto und verknüpfen Sie dieses mit Ihrer bestehenden Kundenkarte.
        </p>
        <br />
        <br />
        <br />
        <form onSubmit={handleSubmit(submitResetPassword)}>
            <TextInput
                required
                type="text"
                label="Kundennummer"
                description="Ihre Kundennummer finden Sie auf Ihrer Kundenkarte."
                {...register('offlineCustomerId')}
            />
            <TextInput
                required
                type="email"
                label="E-Mail"
                {...register('email')}
            />
            <TextInput
                required
                type="password"
                label="Passwort"
                {...register('password')}
            />
            <Button
                title="Passwort zurücksetzen"
                type="submit"
                additionalStyle={{ width: '100%' }}
            />
            <br />
            <Button
                title="Zurück zur Anmeldung"
                type="tertiary"
                destination={'/login'}
            />

        </form>
        {
            loginError !== '' &&
            <div style={{ color: 'red' }}>
                {loginError}
            </div>
        }
    </>;

    return (
        <ThirdContainerSidebar
            sideBarContent={renderResetPassword}
        >
            <img src={KundenkartenImage} style={{
                width: '100%',
                maxWidth: 600,
                float: 'right'
            }} />
            <LoadingSpinner active={loading} type={"page"} />
        </ThirdContainerSidebar>
    )
}

export default ActivateWithCard
